.header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 32px;
  height: 56px;
}

.headerDropdown {
  position: absolute !important;
  top: 8px;
  right: 8px;
}

.topMenu {
  display: flex;
  justify-content: space-between;
  padding: 60px;
  height: 205px;
}

.logoWrapper {
  display: flex;
  justify-content: center;
}

.logo {
  display: block;
  width: 108px;
  height: 44px;
  fill: var(--t-common-text);
}

.appLinks {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.appIcon {
  width: 108px;
  height: 30px;
}

.columnContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  flex: 1 0 auto;
  margin-bottom: 32px;
}

.centerContent {
  align-items: center;
}

.cardBody {
  height: 100%;
  width: 500px;
  padding: 0 27px;
}

@media screen and (max-width: 1200px) {
  .topMenu {
    padding: 32px;
    height: 148px;
  }
}

@media screen and (max-width: 576px) {
  .header {
    padding: 0 16px;
  }

  .topMenu {
    padding: 20px;
    height: 124px;
  }

  .logo {
    width: 98px;
    height: 40px;
  }

  .cardBody {
    height: 100%;
    width: 320px;
    padding: 0 20px;
  }
}
