.root {
  padding: 8px 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-size: 14px;
}

.rightMenu {
  margin: 0;
  padding: 0;
  list-style-type: none;

  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 20px;
  flex-wrap: wrap;
}

.rightMenuItem {
  position: relative;
}

@media screen and (max-width: 1200px) {
  .root {
    padding: 8px 32px;
  }
}

@media screen and (max-width: 576px) {
  .root {
    padding: 8px 20px;
    flex-direction: column;
    align-items: flex-start;
  }

  .rightMenu {
    justify-content: flex-start;
  }
}
