.root {
  color: var(--t-common-text);
}

.left,
.right {
  position: fixed;
  bottom: 0;
  z-index: -1000;
  pointer-events: none;
  fill: var(--t-image-bg);
}

.left {
  left: 0;
}

.right {
  right: 0;
}

.auth-screen-logo {
  fill: var(--t-common-text);
}

@media screen and (min-width: 992px) {
  .root {
    background-attachment: fixed;
    height: 100vh;
  }
}

@media screen and (max-width: 991px) {
  .root {
    min-height: 100vh;
  }
}

@media screen and (max-width: 768px) {
  .right {
    display: none;
  }
}
