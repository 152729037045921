.c-app--theme {
  &--dark {
    // Colors
    --t-plain-bg: #000000;
    --t-contrast-text: #ffffff;
    --t-common-text: #e1e1e1;
    --t-common-text-disabled: rgba(225, 225, 225, 0.35);
    --t-input-bg: rgba(0, 0, 0, 0.5);
    --t-input-bg-contrast: #ffffff;
    --t-input-border: rgba(255, 255, 255, 0.35);
    --t-input-border-contrast: #e4e7ea;
    --t-input-border-focus: rgba(255, 255, 255, 0.2);
    --t-input-text: #e1e1e1;
    --t-input-text-contrast: #3c4b64;
    --t-accent-text: #18dcdc;
    --t-accent-text-contrast-color: #000000;
    --t-accent-text-hover: #00B5B5;
    --t-accent-element: #12a3a3;
    --t-accent-element-text: #e1e1e1;
    --t-accent-element-bg-hover: #0e8080;
    --t-accent-element-border-hover: #0d7575;
    --t-tooltip-bg: #181818;
    --t-panel-bg: #34343b;
    --t-panel-border: #181924;
    --t-special-element-text: #e1e1e1;
    --t-special-item: #20202a;
    --t-sidebar-bg: #2c2c34;
    --t-list-item-hover: rgba(255, 255, 255, 0.1);
    --t-list-item-selected: rgba(242, 242, 242, 0.1);
    --t-card-bg: #23242d;
    --t-card-accent-bg: #202029;
    --t-table-borders: #393A42;
    --t-cancel-btn-bg: #7e8082;
    --t-cancel-btn-bg-hover: #646768;
    --t-profile-email-card-border: #d75871;
    --t-modal-bg: #2c2c34;
    --t-pagination-bg: rgba(0, 0, 0, 0.7);
    --t-pagination-border: #181924;
    --t-pagination-disabled-bg: rgba(255, 255, 255, 0.025);
    --t-pagination-disabled-color: rgba(255, 255, 255, 0.5);
    --t-popup-header-bg: #1A1A23;
    --t-toast-bg: #F3F7F7;
    --t-toast-text: #1B1B1B;
    --t-image-bg: #23242C;
    --t-error-bg: #23242D;

    // Other
    --t-header-shadow: none;
    --t-card-shadow: none;
    --t-elevation-base: 60, 75, 100;
    --t-accent-border: #00B5B5;
    --t-attention-color: #EE6235;
    --t-attention-color-hover: #CE664E;
    --t-badge-billing: #FC7C20;
    --t-badge-system: #E23D3D;
    --t-badge-alert: #73737E;
  }

  &--light {
    // Colors
    --t-plain-bg: #FFFFFF;
    --t-panel-border: #D8DBE0;
    --t-contrast-text: #000000;
    --t-common-text: #3c4b64;
    --t-common-text-disabled: rgba(60, 75, 100, 0.35);
    --t-input-bg: #ffffff;
    --t-input-bg-contrast: rgba(0, 0, 0, 0.5);
    --t-input-border: #e4e7ea;
    --t-input-border-contrast: rgba(255, 255, 255, 0.35);
    --t-input-border-focus: #8ad4ee;
    --t-input-text: #3c4b64;
    --t-input-text-contrast: #e1e1e1;
    --t-accent-text: #00B5B5;
    --t-accent-text-contrast-color: #FFFFFF;
    --t-accent-text-hover: #00B5B5;
    --t-accent-element: #00B5B5;
    --t-accent-element-text: #ffffff;
    --t-accent-element-bg-hover: #008f8f;
    --t-accent-element-border-hover: #008f8f;
    --t-tooltip-bg: #58585b;
    --t-panel-bg: #ffffff;
    --t-special-element-text: #4f5d73;
    --t-special-item: #F0F3F5;
    --t-sidebar-bg: #3c4b64;
    --t-list-item-hover: #ebedef;
    --t-list-item-selected: #f2f2f2;
    --t-card-bg: #F2F4F9;
    --t-card-accent-bg: #ffffff;
    --t-table-borders: #d8dbe0;
    --t-cancel-btn-bg: #648D9B;
    --t-cancel-btn-bg-hover: #557884;
    --t-profile-email-card-border: #ec4364;
    --t-modal-bg: #fff;
    --t-pagination-bg: #fff;
    --t-pagination-border: #c4c9d0;
    --t-pagination-disabled-bg: #fff;
    --t-pagination-disabled-color: #8a93a2;
    --t-popup-header-bg: #E7E9EC;
    --t-toast-bg: #1A3838;
    --t-toast-text: #FFF;
    --t-image-bg: #EFF1F5;
    --t-error-bg: #F2F4F9;

    // Other
      --t-header-shadow: 0 2px 2px 0 rgba(60, 75, 100, .14),
        0 3px 1px -2px rgba(60, 75, 100, .12),
        0 1px 5px 0 rgba(60, 75, 100, .20);
      --t-card-shadow: 0 1px 1px 0 rgba(60, 75, 100, .14),
        0 2px 1px -1px rgba(60, 75, 100, .12),
        0 1px 3px 0 rgba(60, 75, 100, .20);
      --t-accent-border: #00B5B5;
      --t-attention-color: #EE6235;
      --t-attention-color-hover: #CE664E;
      --t-badge-billing: #FC7C20;
      --t-badge-system: #E23D3D;
      --t-badge-alert: #73737E;
  }
}
